import * as React from 'react';
import { ReactNode } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { Navigation, IntegratorMenu, IntegratorCookie, IntegratorFooter } from '../components';

type LayoutProps = {
  children: ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const { data } = useAppContext();

  return (
    <>
      <IntegratorMenu />
      <IntegratorCookie />
      <Navigation />
      {children}
      <IntegratorFooter data={data} />
    </>
  );
}
