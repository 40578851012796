import React, { useState, useEffect } from 'react';
import { Box, Slider, styled, ButtonProps, Button as MUIButton, Dialog } from '@mui/material';
import { DialogTitle, DialogContent, DialogActions, IconButton, Theme, Divider } from '@mui/material';
import { RadioGroup, Radio, FormControlLabel, FormControl, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { CTA } from '@volkswagen-onehub/components-core';
import { useAppContext, useCalculatorContext, useCalculatorProducts } from '../../hooks';
import { ProductList, EnginesList, MainCalculatorButton, LoadingModal } from './components';
import { ICalculatorProduct, ICalculatorEngine, IRepurchases, IFinalPriceData } from '../../types';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { convertEngineToText } from '../../helpers/convertToText';
import { FinancingExcluded } from '../../utils/enums/omnibus';

export type ICalculatorProps = {
  defaultFinancialProduct?: string;
  defaultForFinancialProductEngineCode?: string;
};

export const Calculator = (props: ICalculatorProps) => {
  const { data } = useAppContext();
  const { setCalculationResults } = useCalculatorContext();
  const { dataCalculatorProducts } = useCalculatorProducts();

  const [disabledCountBtn, setDisabledCountBtn] = useState(true);

  const [productSlug, setProductSlug] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');

  const [selectedEngineText, setSelectedEngineText] = useState('');
  const [visibilityOfTheEngineList, setVisibilityOfTheEngineList] = useState<'block' | 'none'>('none');
  const [engines, setEngines] = useState<ICalculatorEngine[] | null>(null);
  const [engineCodes, setEngineCodes] = useState<Array<{
    version: any;
    id: number;
    baseCode: string;
  }> | null>(null);
  const [engineId, setEngineId] = useState(0);
  const [engineBaseCode, setEngineBaseCode] = useState('');
  const [engineInitialPriceNet, setEngineInitialPriceNet] = useState<number | null>(null);

  const [fundingPeriod, setFundingPeriod] = useState(24);
  const [downPaymentOption, setDownPaymentOption] = useState<'Procent' | 'Kwota'>('Procent');

  const [downPaymentAmount, setDownPaymentAmount] = useState<number | null>(null);
  const [downPaymentLimits, setDownPaymentLimits] = useState({ min: 0, max: 20000 });
  const [ownPayment, setOwnPayment] = useState(0);

  const [kilometerLimit, setKilometerLimit] = useState(20000);
  const [repurchase, setRepurchase] = useState(0);

  const [marksFundingPeriods, setMarksFundingPeriods] = useState([24, 36, 48]);
  const [marksOwnPayments, setMarksOwnPayments] = useState(['0.0', '0.05', '0.1', '0.15', '0.2', '0.25', '0.3']);
  const [marksKilometerLimits, setMarksKilometerLimits] = useState([10000, 15000, 20000, 25000, 30000, 35000, 40000]);
  const [marksRepurchases, setMarksRepurchases] = useState<IRepurchases>({ 36: [0.01, 0.05, 0.1, 0.15, 0.2] });

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const { isOnMobileBreakpoint } = useWindowDimensions();
  const classes = useStyles();

  const dataCalculatorProductsLength = dataCalculatorProducts?.length;
  const defaultFinancialProduct = props?.defaultFinancialProduct;

  // set selectedProduct if defaultFinancialProduct exist in dataCalculatorProducts
  useEffect(() => {
    if (selectedProductName === '' && dataCalculatorProductsLength && defaultFinancialProduct) {
      const defaultProduct = dataCalculatorProducts
        ?.filter((product) => product.product_slug === data?.defaultFinancialProduct)
        ?.at(0);
      if (defaultProduct?.product_slug) {
        selectedProductHandler(defaultProduct);
      }
    }
  }, [dataCalculatorProductsLength, defaultFinancialProduct]);

  useEffect(() => {
    if (productSlug && data?.modelName && data?.modelYear) {
      fetch(
        `https://admin.od-reki.volkswagen.pl/api/calculator/products/${productSlug}/calc_model_products?per_page=100&product_slug=${productSlug}&filters[model_name]=${data.modelName}&filters[production_year]=${data.buildYear}`,
        { headers: { 'X-App-Id': 'VW_CENNIKI' } }
      )
        .then((res) => res.json())
        .then((dataCalculatorEngines: ICalculatorEngine[]) => {
          setEngineCodes(
            dataCalculatorEngines.map((engine) => ({
              id: engine.id,
              baseCode: engine.calc_model.mbv,
              version: engine.calc_model.version
            }))
          );
          setEngines(dataCalculatorEngines);

          const defaultEngine = dataCalculatorEngines.find(
            (calculatorEngine) =>
              calculatorEngine.calc_model.mbv === props.defaultForFinancialProductEngineCode?.substring(0, 6)
          );

          if (defaultEngine) {
            setVisibilityOfTheEngineList('none');
            selectedEngineHandler(defaultEngine);
          } else {
            setVisibilityOfTheEngineList('block');
          }
        });
    }
  }, [productSlug]);

  useEffect(() => {
    if (productSlug && engineId) {
      if (
        downPaymentOption === 'Kwota'
          ? downPaymentAmount === null
            ? null
            : downPaymentAmount >= downPaymentLimits.min
          : ownPayment >= 0
      ) {
        setDisabledCountBtn(false);
      } else {
        setDisabledCountBtn(true);
      }
    } else {
      setDisabledCountBtn(true);
    }
  }, [productSlug, engineId, ownPayment, downPaymentOption, downPaymentAmount]);

  const clickOpenHandler = () => setIsOpenDialog(true);
  const clickCloseHandler = () => setIsOpenDialog(false);

  const getFinancingCalcModelURL = (engineId: number) => {
    let payment = ownPayment;
    if (downPaymentOption === 'Kwota') {
      if (downPaymentAmount && engineInitialPriceNet) {
        payment = downPaymentAmount / engineInitialPriceNet;
      }
    }

    const fundingParamsPeriod = `funding_params[funding_period]=${fundingPeriod}`;
    const fundingParamsOwnPayment = `funding_params[own_payment]=${payment}`;
    const fundingParamsKilometerLimit = `funding_params[km_limit]=${kilometerLimit}`;
    const fundingParamsRepurchase = `funding_params[repurchase]=${repurchase}`;

    const optionalFundingParams = kilometerLimit ? fundingParamsKilometerLimit : fundingParamsRepurchase;

    return `https://admin.od-reki.volkswagen.pl/api/calculator/products/${productSlug}/calc_model_products/${engineId}/calculate?${fundingParamsPeriod}&${fundingParamsOwnPayment}&${optionalFundingParams}`;
  };

  const percentageResultHandler = (type: 'showPrices' | 'count') => {
    if (type === 'showPrices') {
      setCalculationResults({
        results: [
          {
            engineBaseCode: '',
            installment: 0,
            priceNet: 0
          }
        ],
        percentage: 1,
        omnibus: true
      });
      setProductSlug('');
      setSelectedProductName('');
      setSelectedEngineText('');
      setEngineId(0);
      setEngineBaseCode('');
      setEngineInitialPriceNet(null);
      setRepurchase(0);
      clickCloseHandler();
    } else if (type === 'count' && engineCodes) {
      setIsLoadingCount(true);

      Promise.all<IFinalPriceData>(
        engineCodes.map((engineCode) =>
          fetch(getFinancingCalcModelURL(engineCode.id), {
            method: 'POST',
            headers: { 'X-App-Id': 'VW_CENNIKI' }
          }).then((res) => res.json())
        )
      )
        .then((dataResults) => {
          const dataResultWithCodes = dataResults.map((dataResult, index) => ({
            engineBaseCode: engineCodes[index].baseCode,
            version: engineCodes[index].version,
            installment: dataResult.installment,
            priceNet: dataResult.price_net
          }));
          const selectedEngineResults = dataResultWithCodes.find(
            (dataWithCode) => dataWithCode.engineBaseCode === engineBaseCode
          );
          const percentage = selectedEngineResults
            ? selectedEngineResults.installment / selectedEngineResults.priceNet
            : 1;

          const newCalculatorResults = {
            results: dataResultWithCodes,
            percentage,
            omnibus: !FinancingExcluded.includes(productSlug)
          };
          setCalculationResults(newCalculatorResults);
          setIsLoadingCount(false);
        })
        .catch((error) => console.log(error));
      clickCloseHandler();
    }
  };

  const getAriaValueTextHandler = (setProperty: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    setProperty(value);
    return `${value}`;
  };

  const selectedProductHandler = (product: ICalculatorProduct) => {
    setProductSlug(product.product_slug);
    setSelectedProductName(product.name);

    setFundingPeriod(product.suggested_funding_period);
    setOwnPayment(+product.suggested_own_payment);
    setKilometerLimit(product.suggested_km_limit ?? 0);
    setRepurchase(product.suggested_repurchase ? +product.suggested_repurchase : 0);

    setMarksFundingPeriods(product.funding_periods);
    setMarksOwnPayments(product.own_payments);
    setMarksKilometerLimits(product.km_limits);
    setMarksRepurchases(product.repurchases);
  };

  const selectedEngineHandler = (engine: ICalculatorEngine) => {
    const minLimit = marksOwnPayments.at(0);
    const maxLimit = marksOwnPayments.at(-1);
    if (minLimit && maxLimit) {
      const min = Math.ceil(+minLimit * engine.calc_model.initial_price_net);
      const max = Math.ceil(+maxLimit * engine.calc_model.initial_price_net);
      setDownPaymentLimits({ min, max });
    }

    setEngineId(engine.id);
    setEngineBaseCode(engine.calc_model.mbv);
    setSelectedEngineText(convertEngineToText(engine));
    setEngineInitialPriceNet(engine.calc_model.initial_price_net);
  };

  const handleChangeDownPaymentOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as 'Procent' | 'Kwota';
    setDownPaymentOption(value);
  };

  const radioControlProps = (item: 'Procent' | 'Kwota') => ({
    checked: downPaymentOption === item,
    onChange: handleChangeDownPaymentOption,
    value: item,
    label: item,
    name: 'color-radio-button',
    control: <Radio color="default" />,
    inputProps: { 'aria-label': item }
  });

  return (
    <div>
      <MainCalculatorButton
        isOpenDialog={isOpenDialog}
        selectedProductName={selectedProductName}
        fundingPeriod={fundingPeriod}
        downPaymentOption={downPaymentOption}
        ownPayment={ownPayment}
        downPaymentAmount={downPaymentAmount}
        repurchase={repurchase}
        kilometerLimit={kilometerLimit}
        onClick={clickOpenHandler}
        onPercentageResult={percentageResultHandler}
      />
      <BootstrapDialog
        fullScreen={isOnMobileBreakpoint}
        onClose={clickCloseHandler}
        aria-labelledby="customized-dialog-title"
        open={isOpenDialog}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={clickCloseHandler}>
          Parametry finansowania
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <h4 style={{ padding: '0 4px' }}>Produkt</h4>
          <ProductList
            selectedProductName={selectedProductName}
            calculatorProducts={dataCalculatorProducts}
            onSelectProduct={selectedProductHandler}
          />
          <h4 style={{ display: visibilityOfTheEngineList, padding: '0 4px' }}>Silnik</h4>
          <EnginesList
            engines={engines}
            productSlug={productSlug}
            visibility={visibilityOfTheEngineList}
            selectedEngineText={selectedEngineText}
            onSelectEngine={selectedEngineHandler}
          />
          <Box className={classes.sliderContainer}>
            <label className={classes.sliderTitle}>
              <span>Okres finansowania</span>
              <b>{fundingPeriod} miesiące</b>
            </label>
            <VWSlider
              step={null}
              aria-label="fundingPeriod"
              defaultValue={fundingPeriod}
              min={marksFundingPeriods[0]}
              max={marksFundingPeriods[marksFundingPeriods.length - 1]}
              marks={marksFundingPeriods.map((mark) => ({ value: mark }))}
              getAriaValueText={(value) => getAriaValueTextHandler(setFundingPeriod, value)}
            />
          </Box>
          <Box className={classes.sliderContainer}>
            <div className={classes.sliderPayment}>
              <div>Wkład własny</div>
              <FormControl>
                <RadioGroup row aria-labelledby="row-radio-buttons-group-label" name="row-radio-buttons-group">
                  {/* @ts-ignore */}
                  <FormControlLabel {...radioControlProps('Procent')} />
                  {/* @ts-ignore */}
                  <FormControlLabel {...radioControlProps('Kwota')} />
                  {downPaymentOption === 'Procent' && (
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', flexGrow: 1 }}>
                      <b>{+ownPayment.toFixed(2) * 100}%</b>
                    </Box>
                  )}
                </RadioGroup>
              </FormControl>
            </div>
            {downPaymentOption === 'Kwota' && (
              <TextField
                id="standard-number"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                sx={{ width: { xs: 330, md: '95%' } }}
                variant="standard"
                placeholder={`Podaj kwotę netto. Min ${downPaymentLimits.min.toLocaleString(
                  'sk-SK'
                )}, Max ${downPaymentLimits.max.toLocaleString('sk-SK')} PLN`}
                inputProps={downPaymentLimits}
                value={downPaymentAmount}
                error={downPaymentAmount ? downPaymentAmount < downPaymentLimits.min : false}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  const { min, max } = downPaymentLimits;
                  if (value > max) {
                    setDownPaymentAmount(max);
                  } else if (value < min) {
                    setDownPaymentAmount(value);
                  } else {
                    setDownPaymentAmount(value);
                  }
                }}
              />
            )}
            {downPaymentOption === 'Procent' && (
              <Box sx={{ paddingY: 1 }}>
                <VWSlider
                  step={null}
                  aria-label="down payment procent"
                  defaultValue={ownPayment}
                  min={+marksOwnPayments[0]}
                  max={+marksOwnPayments[marksOwnPayments.length - 1]}
                  marks={marksOwnPayments.map((mark) => ({ value: +mark }))}
                  getAriaValueText={(value) => getAriaValueTextHandler(setOwnPayment, value)}
                />
              </Box>
            )}
          </Box>
          {kilometerLimit ? (
            <Box className={classes.sliderContainer}>
              <label className={classes.sliderTitle}>
                <span>Roczny limit kilometrów</span>
                <b>{kilometerLimit.toLocaleString('sk-SK')} km</b>
              </label>
              <VWSlider
                step={null}
                aria-label="kilometer limit"
                defaultValue={kilometerLimit}
                min={marksKilometerLimits[0]}
                max={marksKilometerLimits[marksKilometerLimits.length - 1]}
                marks={marksKilometerLimits.map((mark) => ({ value: mark }))}
                getAriaValueText={(value) => getAriaValueTextHandler(setKilometerLimit, value)}
              />
            </Box>
          ) : null}
          {repurchase ? (
            <Box className={classes.sliderContainer}>
              <label className={classes.sliderTitle}>
                <span>Wartość wykupu</span>
                <b>{repurchase * 100}%</b>
              </label>
              <VWSlider
                step={null}
                aria-label="repurchase"
                defaultValue={repurchase}
                min={+marksRepurchases[fundingPeriod][0]}
                max={+marksRepurchases[fundingPeriod][marksRepurchases[fundingPeriod].length - 1]}
                // @ts-ignore: Unreachable code error
                marks={marksRepurchases[fundingPeriod].map((mark) => ({ value: +mark }))}
                getAriaValueText={(value) => getAriaValueTextHandler(setRepurchase, value)}
              />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CTA tag="button" emphasis="secondary" onClick={() => percentageResultHandler('showPrices')}>
            Pokaż ceny
          </CTA>
          <ColorButton variant="contained" onClick={() => percentageResultHandler('count')} disabled={disabledCountBtn}>
            Przelicz
          </ColorButton>
        </DialogActions>
      </BootstrapDialog>
      <LoadingModal open={isLoadingCount}>Raty są zamieniane na ceny</LoadingModal>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  sliderContainer: {
    paddingTop: 15
  },
  sliderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%'
  },
  sliderPayment: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    marginBottom: 2
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',

    margin: '20px 10px'
  }
}));

const VWSlider = styled(Slider)({
  color: '#001E50',
  width: '92%',
  margin: '0 2%',
  '& .MuiSlider-rail': {
    backgroundColor: '#bfbfbf'
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 8,
    borderRadius: 50,
    '&.MuiSlider-markActive': {
      backgroundColor: 'currentColor'
    }
  }
});

const ColorButton = styled(MUIButton)<ButtonProps>(() => ({
  backgroundColor: '#16aef5',
  borderRadius: '22px',
  fontSize: 16,
  textTransform: 'none',
  padding: '10px 50px',
  '&:hover': {
    backgroundColor: '#1291cc'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.up('sm')]: {
      width: 450
    }
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3)
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3)
    }
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  const { isOnMobileBreakpoint } = useWindowDimensions();

  return (
    <DialogTitle sx={{ m: 0, p: 2, py: 3, fontWeight: 700 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 17,
            top: 17,
            color: 'black'
          }}
        >
          {isOnMobileBreakpoint ? <CloseIcon /> : <TuneIcon />}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
