import { useReducer, FormEvent, useState } from 'react';
import { Typography, Theme, Box, Button, styled } from '@mui/material';
import { TextField, Dialog, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { useAppContext } from '../../hooks/useAppContext';
import { IFormInput } from './type';
import { getLeadData, handleRegex } from './formHelpers';

const CssTextField = styled(TextField)({
  marginTop: 15,
  '& .MuiInputBase-root:before': {
    borderBottomColor: 'white'
  },
  '.MuiInputLabel-root': {
    fontSize: '1.2rem'
  },
  '.MuiInput-root': {
    fontSize: '1.4rem'
  }
});

const SubmitButton = styled(Button)({
  border: '2px solid white',
  borderRadius: '40px',
  color: '#ffffffaa',
  width: '150px',
  marginBottom: '40px'
});

const ScrollBox = styled(Box)({
  height: 150,
  overflowY: 'scroll',

  '::-webkit-scrollbar': {
    width: '10px'
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#f2f2f2',
    borderRadius: '10px'
  }
});

type IFormModal = {
  title: string;
  openModal: boolean;
  onDialogState: (flag: boolean) => void;
  onSubmitFormModalHandler: (isVisibleConfirmigModal: boolean, isFormError: boolean) => void;
};

export const FormModal = (props: IFormModal) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();
  const [showMore, setShowMore] = useState(false);

  const { data } = useAppContext();
  // @ts-ignore
  const [formInput, setFormInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    name: '',
    phone: '',
    email: '',
    checkboxMarketing: false,
    checkboxEmail: false,
    checkboxSMS: false,
    checkboxPhone: false
  });

  const handleInput = (evt: FormEvent<HTMLFormElement>, type?: 'checked' | 'tel') => {
    if (evt) {
      if (type === 'checked') {
        // @ts-ignore
        setFormInput({ [evt.target?.name]: evt.target.checked });
      } else {
        // @ts-ignore
        setFormInput({ [evt.target?.name]: evt.target.value });
      }
    }
  };

  const submitHandler = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const formInputsData: IFormInput = { ...formInput };
    const leadData = await getLeadData(formInputsData, data);

    fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify(leadData),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        props.onSubmitFormModalHandler(true, false);
        console.log('Success:', JSON.stringify(response));
      })
      .catch((error) => {
        props.onSubmitFormModalHandler(true, true);
        console.error('Error:', error);
      });
  };

  return (
    <BootstrapDialog
      fullScreen
      onClose={() => props.onDialogState(false)}
      aria-labelledby="customized-dialog-title"
      open={props.openModal}
    >
      {data?.wlsFormUrl ? (
        <>
          <Box
            component="span"
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              top: 20,
              right: 20,
              height: 24,
              borderRadius: '20px'
            }}
            onClick={() => props.onDialogState(false)}
          >
            <CloseIcon />
          </Box>
          <object
            data={data.wlsFormUrl}
            width="100%"
            height="100%"
            style={{ border: 'none', overflow: 'hidden' }}
            type="text/html"
            title="WLS Form"
          />
        </>
      ) : (
        <Box className={classes.container}>
          <Box
            sx={{
              marginBottom: { xs: 0, md: 0 },
              paddingTop: 6
            }}
          >
            <Box
              component="span"
              sx={{
                position: 'absolute',
                backgroundColor: 'white',
                top: 20,
                right: 20,
                height: 24,
                borderRadius: '20px'
              }}
              onClick={() => props.onDialogState(false)}
            >
              <CloseIcon />
            </Box>
            <Typography color="#F2F2F2dd" fontSize={32} align="center">
              {data?.modelNameDeclination ? data.modelNameDeclination : 'Porozmawiajmy o Twoim nowym Volkswagenie'}
            </Typography>
            <Typography color="#F2F2F288" fontSize={20} align="center" paddingTop={2.5}>
              Oddzwonimy i odpowiemy na Twoje pytania, dopasujemy finansowanie i zaprosimy Cię na jazdę próbną.
            </Typography>
            <form onSubmit={(e) => submitHandler(e)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { xs: 'center', md: 'space-between' },
                  margin: { xs: '0 32px 32px', md: '2vw 3vw 2vw' }
                }}
              >
                <CssTextField
                  required
                  id="validation-outlined-input"
                  label="Numer telefonu"
                  type="tel"
                  sx={{ input: { color: 'white', padding: '5px 0' }, width: { xs: '100%', md: '48%' } }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: 'white' }
                  }}
                  error={handleRegex(formInput.phone)}
                  onInvalid={() => handleRegex(formInput.phone)}
                  variant="standard"
                  placeholder="Numer telefonu"
                  // @ts-ignore
                  onChange={(e) => handleInput(e)}
                  onKeyDown={(event) => {
                    if (
                      /[a-zA-Z]/.test(event.key) &&
                      !['Backspace', 'Delete', 'ArrowRight', 'ArrowLeft', 'Tab'].includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  name="phoneNo"
                />
                <CssTextField
                  required
                  id="standard-required"
                  label="Imie"
                  variant="standard"
                  sx={{ input: { color: 'white', padding: '5px 0' }, width: { xs: '100%', md: '48%' } }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: 'white' }
                  }}
                  placeholder="Imię"
                  // @ts-ignore
                  onChange={(e) => handleInput(e)}
                  onKeyDown={(event) => {
                    if (/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  name="name"
                />
              </Box>
              <Box sx={{ fontSize: 14, color: 'white', margin: { xs: '0 32px 32px', md: '0 3vw ' } }}>
                * pola wymagane
              </Box>
              <Box className={classes.text}>
                <ScrollBox>
                  Pozostawienie przez Ciebie danych i wysłanie formularza oznacza Twoją zgodę na skontaktowanie się Tobą
                  w celu udzielenia odpowiedzi na Twoje pytania i przekazanie Tobie szczegółów informacji, o które
                  pytasz, z użyciem telefonu lub adresu e-mail (jeśli chcesz lub go podasz). Mogą być to informacje dot.
                  reklamacji, oferty, jazdy testowej lub usługi serwisowej. Oddzwonimy do Ciebie (VGP lub bezpośrednio
                  Wybrany Autoryzowany Dealer lub Partner Serwisowy marki Volkswagen „Preferowany Dealer”),
                  zweryfikujemy Twoje dane i potwierdzimy Twoje zainteresowanie uzyskaniem informacji od nas. Podanie
                  Twoich danych jest dobrowolne, lecz niezbędne do obsługi zapytania. Następnie Twoje zapytanie, jeśli
                  się zgodzisz, przekażemy do realizacji do wybranego przez Ciebie Autoryzowanego Dealera lub Partnera
                  Serwisowego marki Volkswagen („Preferowany Dealer”). Administratorem przekazanych nam danych jest
                  Volkswagen Group Polska sp. z o.o. z siedzibą w Poznaniu (61-037), ul. Krańcowa 44 wpisana do Rejestru
                  Przedsiębiorców prowadzonego przez Sąd Rejonowy w Poznaniu – Nowe Miasto i Wilda, VIII Wydział
                  Gospodarczy pod numerem KRS 0000327143, NIP 782-24-63-563, REGON 301062169 (VGP) oraz jeśli się
                  zgodzisz także Preferowany Dealer, działający na zasadzie współadministrowania.
                  <br />
                  <br />
                  W każdej chwili możesz cofnąć swoją zgodę i zrezygnować z otrzymania informacji podczas rozmowy
                  informując konsultanta Centrum Informacji lub Preferowanego Dealera o rezygnacji z dalszego kontaktu w
                  tym zakresie (utrata zainteresowania ofertą będzie dla nas jednoznaczna z cofnięciem Twojej zgody w
                  zakresie tego zapytania). Jeśli wyrazisz dodatkowe zgody, możesz je cofnąć poprzez formularz
                  umieszczony na stronie internetowej www.vw-group.pl/pl/formularz-cofniecia-zgod albo bezpośrednio w
                  kontakcie z VGP lub z Autoryzowanym Dealerem / Autoryzowanym Partnerem Serwisowym marki Volkswagen.
                  <br />
                  Więcej informacji dotyczących przetwarzania danych poniżej.
                </ScrollBox>
                <br />
                <Box>
                  <b>MOŻESZ UDZIELIĆ TAKŻE DODATKOWYCH ZGÓD CELEM MARKETINGU PRZYSZŁEGO</b>
                  <br />
                  (ZGODY SĄ OPCJONALNE I DOBROWOLNE, A ICH UDZIELENIE LUB ODMOWA SĄ NIEZALEŻNE OD OBSŁUGI TWOJEGO
                  ZAPYTANIA)
                </Box>
                <br />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'white' } }}
                      checked={formInput.checkboxMarketing}
                      // @ts-ignore
                      onChange={(e) => handleInput(e, 'checked')}
                      name="checkboxMarketing"
                    />
                  }
                  label="Zgadzam się na używanie przez Volkswagen Group Polska Sp. z o.o. (VGP) oraz Volkswagen AG przekazanych danych, danych zebranych o moich zapytaniach i zainteresowaniach, ofertach, zamówieniach i zleceniach w ramach relacji biznesowej z VGP lub z Autoryzowanym Dealerem lub Autoryzowanym Partnerem Serwisowym, w celu marketingu dot. produktów i usług marki Volkswagen oraz innych powiązanych z marką Volkswagen akcesoriów, produktów i usług motoryzacyjnych, w tym także w celu profilowania na potrzeby marketingu oraz realizacji działań posprzedażowych polegających na prowadzeniu i analizie badań satysfakcji klienta lub zainteresowania marką, produktami i usługami marki Volkswagen."
                />
              </Box>
              <Box className={classes.text}>
                {showMore && (
                  <Box>
                    <Box fontSize={14}>Chcę otrzymać od VGP w/w informacje handlowe i marketingowe poprzez:</Box>
                    <Box display="flex" flexDirection={isOnMobileBreakpoint ? 'column' : 'row'}>
                      <FormControlLabel
                        sx={{ paddingRight: { md: '100px' } }}
                        control={
                          <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'white' } }}
                            checked={formInput.checkboxEmail}
                            // @ts-ignore
                            onChange={(e) => handleInput(e, 'checked')}
                            name="checkboxEmail"
                          />
                        }
                        label="Email"
                      />
                      <FormControlLabel
                        sx={{ paddingRight: { md: '100px' } }}
                        control={
                          <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'white' } }}
                            checked={formInput.checkboxPhone}
                            // @ts-ignore
                            onChange={(e) => handleInput(e, 'checked')}
                            name="checkboxPhone"
                          />
                        }
                        label="Telefon"
                      />
                      <FormControlLabel
                        sx={{ paddingRight: { md: '100px' } }}
                        control={
                          <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: 'white' } }}
                            checked={formInput.checkboxSMS}
                            // @ts-ignore
                            onChange={(e) => handleInput(e, 'checked')}
                            name="checkboxSMS"
                          />
                        }
                        label="SMS/MMS"
                      />
                    </Box>
                    {formInput.checkboxEmail && (
                      <Box marginBottom={3}>
                        <CssTextField
                          required={showMore && formInput.checkboxEmail}
                          id="validation-outlined-input"
                          label="Email"
                          type="email"
                          sx={{ input: { color: 'white' }, width: { xs: '100%', md: '48%' } }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'white' }
                          }}
                          variant="standard"
                          placeholder="Podaj email do kontaktu"
                          // @ts-ignore
                          onChange={(e) => handleInput(e)}
                          name="email"
                        />
                      </Box>
                    )}
                  </Box>
                )}
                <a
                  style={{
                    display: 'block',
                    color: '#f2f2f288',
                    fontSize: 12,
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  Zobacz {showMore ? 'mniej' : 'więcej'}
                </a>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitButton variant="outlined" type="submit" disabled={handleRegex(formInput.phone)}>
                  Wyślij
                </SubmitButton>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  margin: '50px 20px 20px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '10%',
    margin: '20px 20px'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'linear-gradient(171deg, rgba(0,34,84,1) 0%, rgba(9,77,136,1) 100%)',
    [theme.breakpoints.up('sm')]: {
      padding: '0 4vw'
    }
  },
  content: {
    margin: '0 8px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 60px'
    }
  },
  text: {
    color: '#f2f2f2',
    fontSize: 15,
    margin: '20px 30px 24px'
  }
}));
