import * as React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckCircle as CheckCircleIcon, RemoveCircleOutline as RemoveCircleIcon } from '@mui/icons-material';

import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { IGroup, ISection, IDetailsSectionType, IItem } from '../../types';
import { useCalculatorContext } from '../../hooks';
import { Tooltip } from '../UI';

export type ITableProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  section: ISection;
  group: IGroup;
  equipmentLines?: string[];
  selectedMobileCol: number;
};

export const Table = (props: ITableProps) => {
  const classes = useStyles();
  const { calculationResults } = useCalculatorContext();
  const { isOnMobileBreakpoint } = useWindowDimensions();

  const isCalculatedEngineResults = (baseCode?: string): boolean =>
    !!calculationResults.results.find((result) => result.engineBaseCode === baseCode?.substring(0, 6));

  const getCalculatedPriceText = (price?: number, baseCode?: string, equipmentLine?: string) => {
    if (price || price === 0) {
      if (baseCode) {
        const engineResults = calculationResults.results.find((result) => {
          if (equipmentLine) {
            return (
              result.engineBaseCode === baseCode?.substring(0, 6) &&
              result.version?.toLowerCase() === equipmentLine.toLowerCase()
            );
          }
          return result.engineBaseCode === baseCode?.substring(0, 6);
        });

        if (engineResults) {
          return `${Math.ceil((price / 1.23) * (engineResults.installment / engineResults.priceNet)).toLocaleString(
            'sk-SK'
          )} PLN netto/mc`;
        } else {
          return `${price.toLocaleString('sk-SK')} PLN`;
        }
      } else {
        if (calculationResults.percentage === 1) {
          return `${price.toLocaleString('sk-SK')} PLN`;
        } else {
          const calculatedPrice = Math.ceil(calculationResults.percentage * price).toLocaleString('sk-SK');
          return `${calculatedPrice} PLN netto/mc`;
        }
      }
    } else return '';
  };

  const getPriceBeforeDiscountDisclaimer = (item: IItem) => {
    return item.priceBeforeDiscountDisclaimer ? (
      <div className={classes.disclaimerOmnibus}>{item.priceBeforeDiscountDisclaimer}</div>
    ) : (
      ''
    );
  };

  const getDesktopTableCells = (group: IGroup, type: IDetailsSectionType, itemsCode?: any) => {
    let keyExtractor: 'engineFullName' | 'code';

    if (type === 'models') {
      keyExtractor = 'engineFullName';
    } else if (type === 'promoFeatures' || type === 'features' || type === 'exteriors' || type === 'interiors') {
      keyExtractor = 'code';
    } else {
      return;
    }

    const newTable = group.items.map((item) => {
      if (type === 'exteriors' || type === 'interiors') {
        return `${item.code}-${group.groupCode}`;
      }
      return item[keyExtractor as keyof IItem] as string;
    });

    const filteredNewTable = newTable?.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    const FIRST_GRID_SIZE = 3.2;

    if (keyExtractor === 'engineFullName' && props?.section?.equipmentLines && filteredNewTable) {
      const emptyArray = [];

      for (const engineFullName of filteredNewTable) {
        const emptyRow = [];
        emptyRow.push(
          <Grid item xs={FIRST_GRID_SIZE} className={classes.cell1}>
            <div>
              <span>{engineFullName} </span>
              <Tooltip
                type="absolute"
                data={group.models && !Array.isArray(group.models) && group.models[engineFullName]?.tooltip}
              />
            </div>
            <span></span>
          </Grid>
        );
        for (const equipmentLine of props?.section?.equipmentLines) {
          const item = group?.items.find(
            (item) => item?.equipmentLine === equipmentLine && item?.engineFullName === engineFullName
          );
          const isBorderColor = emptyRow.length !== props?.section?.equipmentLines.length;

          if (item && item.price) {
            const { price } = item;
            emptyRow.push(
              <Grid
                item
                xs={(12 - FIRST_GRID_SIZE) / props?.section?.equipmentLines.length}
                className={classes.cell2}
                style={{ borderRight: `2px solid ${isBorderColor ? '#DFE4E8' : '#ffffff'}` }}
                key={`${engineFullName}-${equipmentLine}-${props.type}`}
              >
                <>
                  <div className={classes.priceBeforeDiscount}>
                    {item?.priceBeforeDiscount
                      ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode, equipmentLine)
                      : ''}
                  </div>
                  <div style={{ fontWeight: isCalculatedEngineResults(item.baseCode) ? 700 : 400 }}>
                    {getCalculatedPriceText(price, item.baseCode, equipmentLine)}
                  </div>
                  {item && getPriceBeforeDiscountDisclaimer(item)}
                </>
                <Tooltip type="absolute" data={item?.info} />
              </Grid>
            );
          } else {
            emptyRow.push(
              <Grid
                item
                xs={(12 - FIRST_GRID_SIZE) / props?.section?.equipmentLines.length}
                className={classes.cell2}
                style={{ borderRight: `2px solid ${isBorderColor ? '#DFE4E8' : '#ffffff'}` }}
                key={`${Math.random()}`}
              >
                <div></div>
                <div>-</div>
              </Grid>
            );
          }
        }

        emptyArray.push(<Grid container>{emptyRow}</Grid>);
      }

      return emptyArray;
    } else if (
      keyExtractor === 'code' &&
      props.equipmentLines &&
      filteredNewTable &&
      (props.type === 'exteriors' || props.type === 'interiors')
    ) {
      const emptyArray = [];

      for (const interiorCodeGroup of filteredNewTable) {
        const [interiorCode, groupCode] = interiorCodeGroup.split('-');
        const emptyRow = [];
        const item: IItem | undefined = props.section?.groups
          ?.flatMap((group) => group.items)
          ?.find((item: IItem) => item.code === interiorCode && group.groupCode === groupCode);

        if (item) {
          emptyRow.push(
            <Grid item xs={FIRST_GRID_SIZE} className={classes.cell1}>
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', maxWidth: '95%' }}>
                <img src={item.imageUrl} alt={item.name} width={60} height={60} style={{ borderRadius: '30px' }} />
                <p>{item.name} </p>
              </div>
              <Tooltip type="convert" data={item?.availability} />
            </Grid>
          );
        }
        for (const equipmentLine of props?.equipmentLines) {
          const item = group?.items.find(
            (item) =>
              item?.availability?.[equipmentLine] === true &&
              item.code === interiorCode &&
              group.groupCode === groupCode
          );

          const isBorderColor = emptyRow.length !== props?.equipmentLines.length;

          emptyRow.push(
            <Grid
              item
              xs={(12 - FIRST_GRID_SIZE) / props?.equipmentLines?.length}
              className={classes.cell2}
              style={{ borderRight: `2px solid ${isBorderColor ? '#DFE4E8' : '#ffffff'}` }}
              key={`${interiorCode}-${equipmentLine}-${props.type}`}
            >
              <div>
                {item && item.price !== undefined
                  ? item.price > 0
                    ? getCalculatedPriceText(item.price)
                    : 'Bez dopłaty'
                  : '-'}
              </div>
              {item && item.price !== undefined && <Tooltip type="absolute" data={item?.info} />}
            </Grid>
          );
        }

        emptyArray.push(<Grid container>{emptyRow}</Grid>);
      }

      return emptyArray;
    } else if (keyExtractor === 'code' && props?.section?.equipmentLines && filteredNewTable) {
      const emptyArray = [];
      for (const code of filteredNewTable) {
        const emptyRow = [];
        emptyRow.push(
          <Grid item xs={FIRST_GRID_SIZE} className={classes.cell1}>
            <div style={{ paddingRight: `${itemsCode[code]?.tooltip ? '12px' : ''}` }}>{itemsCode[code]?.name}</div>
            <Tooltip type="absolute" data={itemsCode[code]?.tooltip} />
            {itemsCode[code]?.description ? (
              <div
                className={classes.featureDescription}
                dangerouslySetInnerHTML={{ __html: itemsCode[code]?.description }}
              />
            ) : (
              ''
            )}
            {itemsCode[code]?.imageUrl ? (
              <img src={itemsCode[code]?.imageUrl} alt="Akcesoria VW" className={classes.featureImage} />
            ) : (
              ''
            )}
          </Grid>
        );
        for (const equipmentLine of props?.section?.equipmentLines) {
          const item = group?.items.find((item) => item?.equipmentLine === equipmentLine && item?.code === code);
          const isBorderColor = emptyRow.length !== props?.section?.equipmentLines.length;

          emptyRow.push(
            <Grid
              item
              xs={(12 - FIRST_GRID_SIZE) / props?.section?.equipmentLines.length}
              className={classes.cell2}
              style={{
                borderRight: `2px solid ${isBorderColor ? '#DFE4E8' : '#ffffff'}`,
                justifyContent: `${type === 'promoFeatures' ? 'start' : 'center'}`
              }}
              key={`${code}-${equipmentLine}-${props.type}`}
            >
              {item?.isStandard ? (
                <>
                  <CheckCircleIcon />
                  {item?.priceDetails.length !== 0 ? (
                    <Tooltip type="component" data={getStandardDetails(item?.priceDetails)} />
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  <div className={classes.priceBeforeDiscount}>
                    {item?.priceBeforeDiscount ? getCalculatedPriceText(item?.priceBeforeDiscount, equipmentLine) : ''}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: getPrice(item) }} />
                  {item && getPriceBeforeDiscountDisclaimer(item)}
                  {item?.priceDetails.length !== 0 ? (
                    <Tooltip type="component" data={getPriceDetails(item?.priceDetails)} />
                  ) : (
                    ''
                  )}
                  {type === 'promoFeatures' && (
                    <>
                      {packageProfit(item)}
                      {getPackageContent(item?.contentDescription)}
                    </>
                  )}
                </>
              )}
              {item?.isCheaperInPacket ? (
                <>
                  <div>{cheaperInPackage(item)}</div>
                </>
              ) : (
                ''
              )}
            </Grid>
          );
        }
        emptyArray.push(<Grid container>{emptyRow}</Grid>);
      }

      return emptyArray;
    }
  };

  const getPrice = (item?: IItem) => {
    if (item && Object.keys(item.priceDetails).length > 0) {
      let prices: unknown[] = Object.values(item?.priceDetails).filter((n) => n);
      // @ts-ignore
      let min = Math.min(...prices);
      // @ts-ignore
      let max = Math.max(...prices);

      if (min === max) {
        return hasPrice(item) ? getCalculatedPriceText(item?.price) : '-';
      }

      return `od ${getCalculatedPriceText(min)}<br>do ${getCalculatedPriceText(max)}`;
    }

    return hasPrice(item) ? getCalculatedPriceText(item?.price) : '-';
  };

  const getPriceDetails = (prices: any) => {
    if (prices === undefined) {
      return '';
    }

    const rows = [];
    rows.push(
      <tr>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Cena:
        </th>
      </tr>
    );
    for (let engine in prices) {
      rows.push(
        <>
          <tr>
            <td>{engine}</td>
            <td style={{ textAlign: 'right' }}>
              {prices[engine] === null ? (
                <RemoveCircleIcon fontSize={'small'} />
              ) : prices[engine] === 0 ? (
                <CheckCircleIcon />
              ) : (
                prices[engine] + '\u00A0PLN'
              )}
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        <table className={classes.detailsTable}>{rows}</table>
      </>
    );
  };

  const getStandardDetails = (engines: any) => {
    const rows = [];
    rows.push(
      <tr>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Dostępność:
        </th>
      </tr>
    );
    for (let engine in engines) {
      rows.push(
        <tr>
          <td>{engine}</td>
          <td>
            {engines[engine] === 0 ? <CheckCircleIcon fontSize={'small'} /> : <RemoveCircleIcon fontSize={'small'} />}
          </td>
        </tr>
      );
    }

    return (
      <>
        <table className={classes.detailsTable}>{rows}</table>
      </>
    );
  };

  const packageProfit = (item: IItem | undefined) => {
    if (!item?.profit) return;

    return <div className={classes.cheaper}>Zyskujesz do {item?.profit.toLocaleString('sk-SK')} PLN</div>;
  };

  const cheaperInPackage = (item: IItem | undefined) => {
    return (
      <div className={classes.cheaperInPackage}>
        W pakiecie korzystniej!
        <svg
          width="23px"
          height="20px"
          viewBox="0 0 19 16"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9456 12.8997C18.9456 13.3426 18.7278 13.7301 18.3467 13.8962L14.4269 15.8893C14.2636 16 14.1003 16 13.937 16C13.7736 16 13.6103 15.9446 13.447 15.8893L9.52722 13.8962C9.52722 13.8962 9.47278 13.8962 9.47278 13.8408C9.47278 13.8408 9.41834 13.8408 9.41834 13.8962L5.49857 15.8893C5.33524 16 5.17192 16 5.0086 16C4.84527 16 4.68195 15.9446 4.51862 15.8893L0.598854 13.8962C0.217765 13.7301 0 13.2872 0 12.8997L0 9.19031C0 8.74741 0.272206 8.3045 0.707736 8.13841L4.51862 6.47751L4.51862 2.87889C4.51862 2.43599 4.79083 1.99308 5.22636 1.82699L9.14613 0.110727C9.30946 0.0553633 9.41834 0 9.58166 0C9.74499 0 9.85387 0 10.0172 0.110727L13.7736 1.82699C14.2092 1.99308 14.4814 2.43599 14.4814 2.87889L14.4814 6.47751L18.2923 8.13841C18.7278 8.3045 19 8.74741 19 9.19031L18.9456 12.8997L18.9456 12.8997ZM8.49284 9.02422L4.95415 7.47405L1.41547 9.02422L4.95415 10.5744L8.49284 9.02422ZM8.87393 12.8997L8.87393 10.0761L5.49857 11.5709L5.49857 14.6159L8.87393 12.8997ZM13.2837 2.82353L9.41834 1.16263L5.55301 2.87889L9.41834 4.59516L13.2837 2.82353ZM13.3381 6.47751L13.3381 4.09689L9.96275 5.53633L9.96275 7.91695L13.3381 6.47751ZM17.4212 9.02422L13.8825 7.47405L10.3438 9.02422L13.8825 10.5744L17.4212 9.02422ZM17.8023 12.8997L17.8023 10.0761L14.4269 11.5709L14.4269 14.6159L17.8023 12.8997Z"
            id="Shape"
            fill="#001C51"
            stroke="none"
          />
        </svg>
      </div>
    );
  };

  const getPackageContent = (description?: string) => {
    let descriptionItems = description?.split('\r\n') || [];
    if (descriptionItems.length === 0) return;

    return (
      <ul className={classes.packageContent}>
        {descriptionItems.map((item, index) => {
          return (
            <li key={index}>
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const mobileTableRow = (type: IDetailsSectionType, item: IItem, itemsCode?: any) => {
    const { code } = item;

    if (type === 'models') {
      return (
        <Grid container>
          <Grid item xs={7} className={classes.cell1}>
            <div>{item.engineFullName}</div>
            <Tooltip
              type="absolute"
              data={
                props.group.models &&
                !Array.isArray(props.group.models) &&
                item.engineFullName &&
                props.group.models[item.engineFullName]?.tooltip
              }
            />
            <span></span>
          </Grid>
          <Grid item xs={5} className={classes.cell2}>
            <div className={classes.priceBeforeDiscount}>
              {item.priceBeforeDiscount
                ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode, item.equipmentLine)
                : ''}
            </div>
            <div style={{ fontWeight: isCalculatedEngineResults(item.baseCode) ? 700 : 400 }}>
              {getCalculatedPriceText(item.price, item.baseCode, item.equipmentLine)}
            </div>
            {getPriceBeforeDiscountDisclaimer(item)}
            <Tooltip type="absolute" data={item?.info} />
          </Grid>
        </Grid>
      );
    } else if ((type === 'interiors' || type === 'exteriors') && code) {
      const availabilityArray = item.availability ? Object.entries(item.availability) : [];
      const selectedAvailability = availabilityArray[props.selectedMobileCol];

      return (
        <Grid container>
          <Grid item xs={7} className={classes.cell1}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', maxWidth: '95%' }}>
              <img src={item.imageUrl} alt={item.name} width={60} height={60} style={{ borderRadius: '30px' }} />
              <div>{itemsCode.find((item: IItem) => item.code === code)?.name}</div>
            </div>
            <Tooltip type="convert" data={itemsCode.find((item: IItem) => item.code === code)?.availability} />
            <span></span>
          </Grid>
          <Grid item xs={5} className={classes.cell2}>
            <div>
              {selectedAvailability && selectedAvailability[1]
                ? (item?.price ?? 0) > 0
                  ? getCalculatedPriceText(item.price)
                  : 'Bez dopłaty'
                : '-'}
            </div>
            <Tooltip type="absolute" data={item?.info} />
          </Grid>
        </Grid>
      );
    } else if (type === 'promoFeatures' && code) {
      return (
        <Grid container>
          <Grid item xs={5} className={classes.cell1}>
            <div>{itemsCode[code]?.name}</div>
            <div dangerouslySetInnerHTML={{ __html: itemsCode[code]?.description }} />
            <Tooltip type="absolute" data={itemsCode[code]?.tooltip} />
          </Grid>
          <Grid item xs={7} className={classes.cell2}>
            <div className={classes.priceBeforeDiscount}>
              {item?.priceBeforeDiscount ? getCalculatedPriceText(item?.priceBeforeDiscount, item.equipmentLine) : ''}
            </div>
            <div dangerouslySetInnerHTML={{ __html: getPrice(item) }} />
            {item && getPriceBeforeDiscountDisclaimer(item)}
            {packageProfit(item)}
            {getPackageContent(item?.contentDescription)}
          </Grid>
        </Grid>
      );
    } else if (type === 'features' && code) {
      return (
        <Grid container>
          <Grid item xs={5} className={classes.cell1}>
            <div>{itemsCode[code]?.name}</div>
            <Tooltip type="absolute" data={itemsCode[code]?.tooltip} />
            {itemsCode[code]?.description ? (
              <div className={classes.featureDescription}>{itemsCode[code]?.description}</div>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={7} className={classes.cell2}>
            {item.isStandard ? (
              <>
                <CheckCircleIcon />
                {item?.priceDetails.length !== 0 ? (
                  <Tooltip type="component" data={getStandardDetails(item?.priceDetails)} />
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <div className={classes.priceBeforeDiscount}>
                  {item.priceBeforeDiscount
                    ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode, item.equipmentLine)
                    : ''}
                </div>
                <div style={{ fontWeight: isCalculatedEngineResults(item.baseCode) ? 700 : 400 }}>
                  {getCalculatedPriceText(item?.price, item.baseCode, item.equipmentLine)}
                </div>
                {getPriceBeforeDiscountDisclaimer(item)}
              </>
            )}
            {item?.isCheaperInPacket ? <div>{cheaperInPackage(item)}</div> : ''}
          </Grid>
        </Grid>
      );
    } else return <div>Error Row</div>;
  };

  const hasPrice = (item?: IItem) => {
    if (!item) {
      return false;
    }
    return item.price !== undefined || item.price === 0;
  };

  return (
    <>
      {isOnMobileBreakpoint ? (
        <Grid container className={classes.table}>
          {props?.group?.items.map((item, index) => {
            if (
              (['interiors', 'exteriors'].includes(props.type) &&
                props.section.groups
                  ?.flatMap((group) => group.items)
                  ?.some((groupItem) => groupItem.code === item.code)) ||
              (props?.section?.equipmentLines &&
                props.section.equipmentLines[props.selectedMobileCol] === item?.equipmentLine)
            ) {
              return (
                <Grid
                  item
                  xs={12}
                  key={`${item.equipmentLine}-${props.type}-${index}`}
                  className={index ? classes.td : ''}
                >
                  {['interiors', 'exteriors'].includes(props.type)
                    ? mobileTableRow(props.type, item, props.section?.groups?.flatMap((group) => group.items) || [])
                    : mobileTableRow(props.type, item, props.section.items)}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      ) : (
        <Grid container className={classes.table}>
          {getDesktopTableCells(props.group, props.type, props.section.items)?.map((desktopTableCell, index) => (
            <Grid
              key={`dasktop-grid-${props.type}-${index}`}
              item
              xs={12}
              className={`${classes.selection} ${index ? classes.td : ''}`}
            >
              {desktopTableCell}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderTop: '2px solid #001E50',
    borderBottom: '2px solid #001E50'
  },
  td: {
    borderTop: '2px solid #DFE4E8'
  },
  selection: {
    '&:hover': {
      backgroundColor: '#001E500D'
    }
  },
  cell1: {
    position: 'relative',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start'
    },
    fontSize: 14,
    fontWeight: 700,
    color: '#001E50',
    borderRight: '2px solid #001E50',
    padding: '12px 16px'
  },
  cell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    color: '#001E50',
    paddingTop: '10px',
    paddingBottom: '5px',
    position: 'relative'
  },
  priceBeforeDiscount: {
    color: '#6A767D',
    textDecorationLine: 'line-through'
  },
  disclaimerOmnibus: {
    color: '#6A767D',
    lineHeight: 1.1,
    textAlign: 'center',
    maxWidth: '75%',
    margin: '5px 0'
  },
  packageContent: {
    marginLeft: '35px',
    width: '90%',
    marginTop: '10px',
    paddingRight: '8px',
    '& span': {
      color: '#6A767D',
      fontFamily: 'VW Head',
      fontSize: '12px'
    }
  },
  cheaper: {
    fontFamily: 'VW Text',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#001e50',
    backgroundColor: '#dfe4e8',
    minHeight: '24px',
    minWidth: '158px',
    borderRadius: '12px',
    marginTop: '10px'
  },
  cheaperInPackage: {
    fontFamily: 'VW Text',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#001e50',
    backgroundColor: '#dfe4e8',
    minHeight: '24px',
    minWidth: '158px',
    borderRadius: '12px',
    marginTop: '10px',
    padding: '5px 10px',
    '& svg': {
      marginLeft: '10px',
      verticalAlign: 'middle'
    }
  },
  detailsTable: {
    borderCollapse: 'collapse',
    '& tr:not(:last-of-type) td': {
      borderBottom: '1px solid gray'
    },
    '& td': {
      padding: '3px 5px 3px 5px',
      fontSize: '12px',
      fontWeight: 400
    }
  },
  featureImage: {
    width: '178px',
    height: '100px',
    margin: '10px 0'
  },
  featureDescription: {
    fontWeight: 400,
    fontSize: '14px',
    margin: '10px 0'
  }
}));
