import { useState, memo, useEffect } from 'react';
import { Box, styled, ButtonProps, Button as MUIButton } from '@mui/material';
import { Theme, ButtonGroup, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TuneIcon from '@mui/icons-material/Tune';

import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { useCalculatorContext } from '../../../hooks';

export type IMainCalculatorButtonProps = {
  isOpenDialog: boolean;
  selectedProductName: string;
  fundingPeriod: number;
  downPaymentOption: 'Procent' | 'Kwota';
  ownPayment: number;
  downPaymentAmount: number | null;
  repurchase: number;
  kilometerLimit: number;
  onClick: () => void;
  onPercentageResult: (type: 'showPrices' | 'count') => void;
};

export const MainCalculatorButton = memo((props: IMainCalculatorButtonProps) => {
  const [isOpenGroupButton, setIsOpenGroupButton] = useState(true);

  const { isOnMobileBreakpoint } = useWindowDimensions();
  const { calculationResults } = useCalculatorContext();
  const classes = useStyles();

  useEffect(() => {
    // data for PDF - only page with path '...html' can change this data
    if (window.location.pathname.includes('html')) {
      localStorage.setItem('calculationResults', JSON.stringify(calculationResults));
      localStorage.setItem(
        'calculatorParameters',
        JSON.stringify({
          selectedProductName: props.selectedProductName,
          fundingPeriod: props.fundingPeriod,
          downPaymentOption: props.downPaymentOption,
          ownPayment: props.ownPayment,
          downPaymentAmount: props.downPaymentAmount,
          repurchase: props.repurchase,
          kilometerLimit: props.kilometerLimit
        })
      );
    }
  }, [calculationResults]);

  return (
    <>
      {isOnMobileBreakpoint || props.isOpenDialog || calculationResults.percentage === 1 ? (
        <RootButton
          variant="contained"
          onClick={props.onClick}
          sx={{ color: props.selectedProductName ? '#001E50' : '#747474' }}
          className={classes.btn}
        >
          {props.isOpenDialog || calculationResults.percentage === 1 ? (
            <Box paddingRight={2}>Pokaż raty</Box>
          ) : (
            <Box sx={{ display: 'flex', maxWidth: 170 }}>
              <Typography fontSize={13} fontWeight="bold" textAlign="left">
                {props.selectedProductName}
              </Typography>
            </Box>
          )}
          <TuneIcon color="inherit" />
        </RootButton>
      ) : (
        <RootButtonGroup
          variant="text"
          sx={{ color: props.selectedProductName ? '#001E50' : '#747474' }}
          className={classes.btn}
        >
          <DividedGroupButton onClick={props.onClick} sx={{ display: 'flex', maxWidth: 200, paddingLeft: 4 }}>
            <Typography fontSize={13} fontWeight="bold" textAlign="left">
              {props.selectedProductName}
            </Typography>
          </DividedGroupButton>

          <DividedGroupButton onClick={() => setIsOpenGroupButton((prev) => !prev)}>
            <TuneIcon color="inherit" />
          </DividedGroupButton>

          {isOpenGroupButton && (
            <DividedGroupButton onClick={props.onClick} sx={{ display: 'flex', width: 250 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Box>
                    <Typography fontSize={13} color="gray">
                      Okres [mc]
                    </Typography>
                  </Box>
                  <Box sx={{ fontWeight: 'bold' }}>{props.fundingPeriod}</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <Typography fontSize={13} color="gray">
                      Wkład [{props.downPaymentOption === 'Procent' ? '%' : 'zł'}]
                    </Typography>
                  </Box>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {props.downPaymentOption === 'Procent'
                      ? props.ownPayment * 100
                      : props.downPaymentAmount?.toLocaleString('sk-SK')}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <Typography fontSize={13} color="gray">
                      {props.repurchase ? 'Wykup [%]' : 'Limit [km]'}
                    </Typography>
                  </Box>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {props.repurchase
                      ? (props.repurchase * 100).toLocaleString('sk-SK')
                      : props.kilometerLimit.toLocaleString('sk-SK')}
                  </Box>
                </Grid>
              </Grid>
            </DividedGroupButton>
          )}

          <DividedGroupButton onClick={() => props.onPercentageResult('showPrices')}>
            <Typography
              fontSize={13}
              fontWeight="bold"
              sx={{ textDecoration: 'underline' }}
              color="#4CC7F4"
              textAlign="left"
              width={60}
              paddingX={1}
            >
              Pokaż ceny
            </Typography>
          </DividedGroupButton>
        </RootButtonGroup>
      )}
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    bottom: 34,
    right: 100,
    [theme.breakpoints.up('sm')]: {
      right: 104,
      bottom: 50
    }
  }
}));

const RootButton = styled(MUIButton)<ButtonProps>(() => ({
  position: 'fixed',
  zIndex: 100,
  backgroundColor: '#F4F4F4',
  height: 64,
  borderRadius: '32px',
  fontSize: 16,
  textTransform: 'none',
  padding: '10px 30px',
  '&:hover': {
    backgroundColor: '#d4d4d4'
  }
}));

const DividedGroupButton = styled(MUIButton)<ButtonProps>(() => ({
  backgroundColor: '#F4F4F4',
  height: 64,
  fontSize: 16,
  color: 'inherit',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#d4d4d4'
  }
}));

const RootButtonGroup = styled(ButtonGroup)(() => ({
  position: 'fixed',
  zIndex: 100,
  backgroundColor: '#F4F4F4',
  height: 64,
  borderRadius: '32px',
  fontSize: 16,
  textTransform: 'none',
  overflow: 'hidden',
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: '#001E5033'
  }
}));
