// @ts-nocheck
import { useState } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { Typography, Theme, Box, Button, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import parse from 'html-react-parser';

import { useAppContext } from '../../hooks';
import { IData } from '../../types';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { FormModal, ConfirmingModal } from '../';
import { Tooltip } from '../UI';

export type IHeaderSectionProps = {
  data: IData;
};

const StyledButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  marginTop: 15,
  padding: '16px 20px',
  lineHeight: 1.5,
  backgroundColor: '#00B0F0',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: '#4cc7f4',
    boxShadow: 'none'
  }
});

export const HeaderSection = (props: IHeaderSectionProps) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();
  const modelName = useAppContext().data?.modelName;
  const [openDialog, setOpenDialog] = useState(false);
  const [visibleConfirmigModal, setVisibleConfirmigModal] = useState(false);
  const [errorOfSubmittedForm, setErrorOfSubmittedForm] = useState(false);

  const handleDialog = (flag: boolean) => setOpenDialog(flag);

  const clickButtonHandler = () => {
    const { headerButton } = props.data;
    if (headerButton.behavior === 'modal') {
      setOpenDialog(true);
    } else if (headerButton.behavior === 'link' && headerButton.link) {
      if (headerButton.link.startsWith('#')) {
        const element = document.querySelector(headerButton.link);
        element?.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.open(headerButton.link, '_blank', 'noopener');
      }
    }
  };

  const submitFormModalHandler = (isVisibleConfirmigModal: boolean, isFormError: boolean) => {
    setOpenDialog(false);
    setVisibleConfirmigModal(isVisibleConfirmigModal);
    setErrorOfSubmittedForm(isFormError);
  };

  const closeConfirmingModalHandler = () => {
    setVisibleConfirmigModal(false);
  };

  const getDescription = (description: string, tooltip: string) => {
    const options = {
      replace: (rep) => {
        if (rep.data?.includes('%tooltip%')) {
          return (
            <>
              <span>{rep.data.replace('%tooltip%', '')}</span>
              <Tooltip type="header" data={tooltip} />
            </>
          );
        }
      }
    };

    return parse(description, options);
  };

  return (
    <header className={classes.container}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isOnMobileBreakpoint ? '100%' : '3fr 2fr',
          gridTemplateRows: isOnMobileBreakpoint ? 'auto auto' : 'auto'
        }}
      >
        <Box
          sx={{
            gridColumn: isOnMobileBreakpoint ? 1 : 2,
            gridRow: 1,
            order: isOnMobileBreakpoint ? -1 : 'initial',
            margin: '3vw'
          }}
        >
          <Typography variant="h1" className={classes.h1} align="left">
            {props.data.modelName}
          </Typography>
          <Typography variant="h4" className={classes.h4} align="left">
            <Box marginTop={2} className={classes.headerContent}>
              {props.data.description && getDescription(props.data.description, props.data.descriptionTooltip)}
            </Box>
          </Typography>
          <Typography variant="h4" className={classes.h5} align="left">
            <Text>
              <span style={{ color: 'gray' }}>Rok modelowy: {props.data.modelYear} | </span>
              <span style={{ color: 'gray' }}>Rok produkcji: {props.data.buildYear}</span>
            </Text>
            <Text>
              <Box marginTop={1} style={{ color: 'gray' }}>
                Cennik ważny od {props.data.validFrom}
              </Box>
            </Text>
          </Typography>
          {props.data.headerButton && (
            <StyledButton variant="contained" onClick={(e) => clickButtonHandler()}>
              <span className={`${props.data.headerButton.icon} icon-header`} />
              {props.data.headerButton.description}
            </StyledButton>
          )}
        </Box>
        <Box
          sx={{
            position: 'relative',
            overflow: isOnMobileBreakpoint ? 'hidden' : 'visible',
            backgroundColor: !isOnMobileBreakpoint && '#001E50',
            height: isOnMobileBreakpoint ? '100%' : '34vw',
            width: isOnMobileBreakpoint ? '100%' : '40vw'
          }}
        >
          {props.data.headerImageUrl && (
            <Box
              component="img"
              sx={{
                height: isOnMobileBreakpoint ? 'auto' : '30vw',
                width: isOnMobileBreakpoint ? '100%' : 'auto',
                padding: isOnMobileBreakpoint && '3vw',
                backgroundColor: isOnMobileBreakpoint && '#001E50',
                margin: !isOnMobileBreakpoint && '2vw 0 2vw 3vw'
              }}
              src={props.data.headerImageUrl}
              alt={props.data.modelName}
            />
          )}
        </Box>
      </Box>
      {modelName && (
        <FormModal
          title={modelName}
          openModal={openDialog}
          onDialogState={handleDialog}
          onSubmitFormModalHandler={submitFormModalHandler}
        />
      )}
      <ConfirmingModal
        isError={errorOfSubmittedForm}
        open={visibleConfirmigModal}
        onClose={closeConfirmingModalHandler}
      />
    </header>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {
      marginTop: 70
    }
  },
  content: {
    margin: '0 8px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 60px'
    }
  },
  headerContent: {
    fontFamily: 'VW Head'
  },
  h1: {
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'VW Head',
    [theme.breakpoints.up('sm')]: {
      fontSize: 48
    }
  },
  h4: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 32
  }
}));
